.videos {
  color: rgb(178, 148, 46);
  margin-top: 6rem;
  padding: 0 4rem;
}

.videoSection {
  padding: 2rem 0;
}

.videoSection h4 {
  padding-bottom: .5rem;

}

.videoSection iframe {
  padding: 1rem;

}