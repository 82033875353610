.banner {
  background-color: grey;
  width: 100vw;
  height: 6rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
}

.banner h5 {
  margin-right: 4rem;
}

.getStarted {
  text-decoration: none;
  color: white;
}

.getStartedButton {
  /* margin-left: 4rem; */
}

.getStartedButton:hover {
  background-color: #b3b3b3;
}