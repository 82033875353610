.galleryImages {
  height: 500px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 100px;
  background-repeat: no-repeat;
  background-size: 100%;
}

.carouselCaption {
  color: white;
  text-align: center;
}

.feature {
  display: flex;
  flex-direction: row;
  margin: 2rem 4rem;
}

.featureLeft {
  color: rgb(178, 148, 46);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-right: 1rem;
}

.featureRight {
  padding-left: 1rem;
}

.services {
  display: flex;
  flex-direction: column;
}

.servicesHeader {
  margin: 2rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: rgb(178, 148, 46);
}

.servicesCards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2rem 0;
}