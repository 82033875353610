.process {
  color: rgb(178, 148, 46);
  margin-top: 6rem;
  padding: 0 4rem;
}

.processHeader {
  text-align: center;
  padding-bottom: 2rem;
}

.oddProcess {
  display: flex;
  flex-direction: row;
  margin: 2rem 0;
}

.evenProcess {
  display: flex;
  flex-direction: row-reverse;
  margin: 2rem 0;
}

.processText {
  padding: 0 2rem;
}

@media (max-width: 900px)  {
  /* .processText {
    align-items: center;
    justify-content: center;
    text-align: center;
  } */
  .oddProcess {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .evenProcess {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}