nav {
  height: 5rem;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-color: #f0f0f0 !important;
  color: #5c5c5c;
  z-index: 1000;
  box-shadow: 0 4px 4px -3px rgba(0,0,0,.4);
}

nav header {
  background-color: #f0f0f0 !important;
  height: 100%;
}

nav a {
  text-decoration: none;
  color: #5c5c5c;
}

.Logo {
  margin-bottom: .7rem;

}

.Logo a {
  margin-left: 50px;
  font-size: 22px;
  /* font-weight: 300; */
  letter-spacing: 4px;
  display: flex;
  align-items: flex-end;
}

.Logo a img {
  height: 60px;
  width: auto;
  margin-right: 1rem;
}

.Logo a span {
  margin-bottom: .5rem;
}

nav ul {
  display: flex;
  /* align-items: flex-end; */
  flex-direction: row;
  list-style-type: none;
  margin-right: 50px;
  margin-bottom: 1.2rem;
}

nav ul li a {
  margin: 0 15px;
  font-size: 18px;
  /* font-weight: 300; */
  letter-spacing: 3px;
}

.list-item a {
  color: #5c5c5c;
  text-decoration: none;
  font-family: 'League Spartan',
  sans-serif;
}

.navBar {
  background-color: white !important;
}

.appLogo {
  margin-left: 20px;
  height: 50px;
  width: auto;
}

.navList {
  color: black;
  text-decoration: none;
}


/* @media (max-width: 400px)  {
  appLogo {
    margin-left: 20px;
  }
  nav ul {
    flex-direction: column;
    align-items: flex-end;
    margin-right: 20px;
    margin-bottom: 0px;
  }

  nav ul li {
    margin-bottom: 10px;
  }

  nav ul li a {
    margin-bottom: unset;
  }
 } */